import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyDsqi77x54KUbkOJwrQM3HqmUqbiWlgLSs",
  authDomain: "couture-lab-backend.firebaseapp.com",
  projectId: "couture-lab-backend",
  storageBucket: "couture-lab-backend.appspot.com",
  messagingSenderId: "515162456588",
  appId: "1:515162456588:web:c29769a33d60529f112429",
  measurementId: "G-FYBL8ZDND6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const FirebaseAuth = getAuth(app);
const storage = getStorage(app);

export default app;
export { FirebaseAuth, storage, db };